
export interface IMenu {
  entryId?: string;
  menuTitle: string;
  menuItem?: IMenuItem[];
  modifiedOn?: string;
}

export interface IMenuItem {
  menuItemTitle: string;
  menuItemSlug: string;
  subMenuItem?: ISubMenuItem[];
}

export interface ISubMenuItem {
  subMenuItemTitle: string;
  subMenuItemSlug: string;
  subSubMenuItem?: ISubSubMenuItem[];
}

export interface ISubSubMenuItem {
  subSubMenuItemTitle: string;
  subSubMenuItemSlug: string;
}

export class Menu implements IMenu {
  entryId?: string;
  menuTitle: string;
  menuItem?: IMenuItem[];
  modifiedOn?: string;

  constructor(menu: IMenu) {
    this.entryId = menu.entryId;
    this.menuTitle = menu.menuTitle;
    this.menuItem = menu.menuItem;
    this.modifiedOn = menu.modifiedOn;
  }
}
