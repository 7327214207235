import { gql } from "apollo-angular";

const LIST_CLIENTS_WITH_OPENIG_HOURS_QUERY = gql`query {
    listClients {
      data {
        entryId
        clientCompanyName
        clientCompanyType
        clientSocialCapital
        clientLegalRepresentative
        clientRcs
        clientEmail
        clientOpeningHours {
          mondayHours {
            mondayTimeBlocks {
              mondayOpen
              mondayClose
            }
          }
          tuesdayHours {
            tuesdayTimeBlocks {
              tuesdayOpen
              tuesdayClose
            }
          }
          wednesdayHours {
            wednesdayTimeBlocks {
              wednesdayOpen
              wednesdayClose
            }
          }
          thursdayHours {
            thursdayTimeBlocks {
              thursdayOpen
              thursdayClose
            }
          }
          fridayHours {
            fridayTimeBlocks {
              fridayOpen
              fridayClose
            }
          }
          saturdayHours {
            saturdayTimeBlocks {
              saturdayOpen
              saturdayClose
            }
          }
          sundayHours {
            sundayTimeBlocks {
              sundayOpen
              sundayClose
            }
          }
        }
        clientLogo
        clientHeadOfficeAddress
        clientCnil
        clientPhoneNumber
      }
    }
  }`;

const LIST_CLIENTS_QUERY = gql`query {
    listClients {
      data {
        entryId
        clientCompanyName
        clientCompanyType
        clientSocialCapital
        clientLegalRepresentative
        clientRcs
        clientEmail
        clientLogo
        clientHeadOfficeAddress
        clientCnil
        clientPhoneNumber
      }
    }
  }`;


const CLIENT_BY_ID_WITH_OPENING_HOURS_QUERY = gql`
  query getClient($id: String!) {
    getClient(where: { entryId: $id }) {
      data {
        entryId
        clientCompanyName
        clientCompanyType
        clientSocialCapital
        clientLegalRepresentative
        clientRcs
        clientEmail
        clientOpeningHours {
          mondayHours {
            mondayTimeBlocks {
              mondayOpen
              mondayClose
            }
          }
          tuesdayHours {
            tuesdayTimeBlocks {
              tuesdayOpen
              tuesdayClose
            }
          }
          wednesdayHours {
            wednesdayTimeBlocks {
              wednesdayOpen
              wednesdayClose
            }
          }
          thursdayHours {
            thursdayTimeBlocks {
              thursdayOpen
              thursdayClose
            }
          }
          fridayHours {
            fridayTimeBlocks {
              fridayOpen
              fridayClose
            }
          }
          saturdayHours {
            saturdayTimeBlocks {
              saturdayOpen
              saturdayClose
            }
          }
          sundayHours {
            sundayTimeBlocks {
              sundayOpen
              sundayClose
            }
          }
        }
        clientLogo
        clientHeadOfficeAddress
        clientCnil
        clientPhoneNumber
      }
    }
  }`;

const OPENING_HOURS_BY_CLIENT_ID_QUERY = gql`
  query getClient($id: String!) {
    getClient(where: { entryId: $id }) {
      data {
        entryId
        clientOpeningHours {
          mondayHours {
            mondayTimeBlocks {
              mondayOpen
              mondayClose
            }
          }
          tuesdayHours {
            tuesdayTimeBlocks {
              tuesdayOpen
              tuesdayClose
            }
          }
          wednesdayHours {
            wednesdayTimeBlocks {
              wednesdayOpen
              wednesdayClose
            }
          }
          thursdayHours {
            thursdayTimeBlocks {
              thursdayOpen
              thursdayClose
            }
          }
          fridayHours {
            fridayTimeBlocks {
              fridayOpen
              fridayClose
            }
          }
          saturdayHours {
            saturdayTimeBlocks {
              saturdayOpen
              saturdayClose
            }
          }
          sundayHours {
            sundayTimeBlocks {
              sundayOpen
              sundayClose
            }
          }
        }
      }
    }
  }`;

const CLIENT_BY_ID_QUERY = gql`
  query getClient($id: String!) {
    getClient(where: { entryId: $id }) {
      data {
        entryId
        clientCompanyName
        clientCompanyType
        clientSocialCapital
        clientLegalRepresentative
        clientRcs
        clientEmail
        clientLogo
        clientHeadOfficeAddress
        clientCnil
        clientPhoneNumber
      }
    }
  }`;


export {
  LIST_CLIENTS_QUERY,
  CLIENT_BY_ID_QUERY,
  LIST_CLIENTS_WITH_OPENIG_HOURS_QUERY,
  CLIENT_BY_ID_WITH_OPENING_HOURS_QUERY,
  OPENING_HOURS_BY_CLIENT_ID_QUERY
};
