import { gql } from "apollo-angular";

const LIST_ALBUMS_WITH_PHOTOS_QUERY = gql`{
  listAlbums {
    data {
      entryId
      albumTitle
      albumPhotos {
        entryId
        modifiedOn
        photoTitle
        photoDescription
        photoImage
        photoAltText
        photoTags
        photoHypertextLink
      }
    }
  }
}`;

const LIST_ALBUMS_QUERY = gql`{
  listAlbums {
    data {
      entryId
      albumTitle
    }
  }
}`;

const ALBUM_BY_ID_QUERY = gql`
  query getAlbum($id: String!) {
    getAlbum(where: { entryId: $id }) {
      data {
        entryId
        albumTitle
      }
    }
  }`;

const ALBUM_WITH_PHOTOS_BY_ID_QUERY = gql`
  query getAlbum($id: String!) {
    getAlbum(where: { entryId: $id }) {
      data {
        entryId
        albumTitle
        albumPhotos {
          entryId
          modifiedOn
          photoTitle
          photoDescription
          photoImage
          photoAltText
          photoTags
          photoHypertextLink
        }
      }
    }
  }`;

const PHOTOS_BY_ALBUM_ID_QUERY = gql`
  query getAlbum($id: String!) {
    getAlbum(where: { entryId: $id }) {
      data {
        entryId
        albumPhotos {
          entryId
          modifiedOn
          photoTitle
          photoDescription
          photoImage
          photoAltText
          photoTags
          photoHypertextLink
        }
      }
    }
  }`;

export {
  LIST_ALBUMS_QUERY,
  LIST_ALBUMS_WITH_PHOTOS_QUERY,
  ALBUM_BY_ID_QUERY,
  ALBUM_WITH_PHOTOS_BY_ID_QUERY,
  PHOTOS_BY_ALBUM_ID_QUERY,
};
