import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LIST_FEATURES_QUERY } from '../graphql/feature';
import { Feature } from '../models/feature.model';

@Injectable({
  providedIn: 'root'
})
export class FeatureService {
  constructor(private apollo: Apollo) { }

  getFeatureList(): Observable<Feature[]> {
    return this.apollo.watchQuery<any>({
      query: LIST_FEATURES_QUERY
    }).valueChanges.pipe(map(
      (result) => result.data.listFeatures.data.map((c: any) => new Feature(c))
      ));
  }
}
