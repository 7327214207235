import { gql } from "apollo-angular";

const LIST_SOCIAL_MEDIAS_QUERY = gql`
  query {
    listSocialMediaEntries {
      data {
        entryId
        socialMediaTitle
        socialMediaUrl
        socialMediaAltText
        socialMediaSelection
        socialMediaOtherSelection {
          socialMediaOtherSelectionName
          socialMediaOtherSelectionLogo
        }
      }
    }
  }`;

  export {
    LIST_SOCIAL_MEDIAS_QUERY,
  };
