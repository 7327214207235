import { SerializedLexicalEditorState } from "@yco-consulting/ngx-webiny-richtext-parser";

export interface IArticle {
  entryId: string;
  articleTitle: string;
  articleImage: string | null;
  articleAuthor: string;
  articleContent: SerializedLexicalEditorState;
  modifiedOn?: string;
}

export class Article implements IArticle {
  entryId: string;
  articleTitle: string;
  articleImage: string | null;
  articleAuthor: string;
  articleContent: SerializedLexicalEditorState;

  constructor(article: IArticle) {
    this.entryId = article.entryId;
    this.articleTitle = article.articleTitle;
    this.articleImage = article.articleImage;
    this.articleAuthor = article.articleAuthor;
    this.articleContent = article.articleContent;
  }
}
