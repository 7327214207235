import { SerializedLexicalEditorState } from "@yco-consulting/ngx-webiny-richtext-parser";

export interface ITeam {
  entryId: string;
  teamTitle: string;
  teamHistory?: string;
  teamCollaborators: ICollaborator[];
}
export interface ICollaborator {
  entryId: string;
  collaboratorName: string;
  collaboratorPhoto?: string;
  collaboratorPosition: string;
  collaboratorShortDescription: string;
  collaboratorLongDescription: string;
  collaboratorEmail?: string;
  collaboratorPhoneNumber?: string;
}

export class Team implements ITeam {
  entryId: string;
  teamTitle: string;
  teamHistory?: string;
  teamCollaborators: ICollaborator[];

  constructor(team: ITeam) {
    this.entryId = team.entryId;
    this.teamTitle = team.teamTitle;
    this.teamHistory = team.teamHistory;
    this.teamCollaborators = team.teamCollaborators;
  }
}

export class Collaborator implements ICollaborator {
  entryId: string;
  collaboratorName: string;
  collaboratorPhoto?: string | undefined;
  collaboratorPosition: string;
  collaboratorShortDescription: string;
  collaboratorLongDescription: string;
  collaboratorEmail?: string | undefined;
  collaboratorPhoneNumber?: string | undefined;

  constructor(collab: ICollaborator) {
    this.entryId = collab.entryId;
    this.collaboratorName = collab.collaboratorName;
    this.collaboratorPhoto = collab.collaboratorPhoto;
    this.collaboratorPosition = collab.collaboratorPosition;
    this.collaboratorShortDescription = collab.collaboratorShortDescription;
    this.collaboratorLongDescription = collab.collaboratorLongDescription;
    this.collaboratorEmail = collab.collaboratorEmail;
    this.collaboratorPhoneNumber = collab.collaboratorPhoneNumber;
  }
}
