import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ClientService } from './services/client.service';
import { HttpClientModule } from '@angular/common/http';
import { ArticleService } from './services/article.service';
import { NgxWebinyRichtextParserComponent } from '@yco-consulting/ngx-webiny-richtext-parser';
import { CommonModule } from '@angular/common';
import { SerializedLexicalEditorState } from '@yco-consulting/ngx-webiny-richtext-parser/lib/models/lexical-types';
import { MenuService } from './services/menu.service';
import { AlbumService } from './services/album.service';
import { TeamService } from './services/team.service';
import { FAQService } from './services/faq.service';
import { FeatureService } from './services/feature.service';
import { OfferService } from './services/offer.service';
import { SocialMediaService } from './services/social-media.service';
import { ContactFormService } from './services/contact-form.service';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ContactForm } from './models/contact-form.model';


@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  imports: [CommonModule, RouterOutlet, HttpClientModule, NgxWebinyRichtextParserComponent, ReactiveFormsModule]
})
export class AppComponent implements OnInit {
  title = 'fontend';
  jsonHere = '';
  jsonHere2 = '';
  webinyRichTextObject: SerializedLexicalEditorState | undefined;

  contactForm: FormGroup = this.fb.group({
    message: ['', Validators.required]
  });

  constructor(
    private readonly fb: FormBuilder,
    private readonly albumService: AlbumService,
    private readonly articleService: ArticleService,
    private readonly clientService: ClientService,
    // private readonly contactFormService: ContactFormService,
    private readonly faqService: FAQService,
    private readonly featureService: FeatureService,
    private readonly menuService: MenuService,
    private readonly offerService: OfferService,
    private readonly socialMediaService: SocialMediaService,
    private readonly teamService: TeamService,
  ) {
  }

  sendForm() {
    const formData: ContactForm = new ContactForm();
    formData.contactFormEmail = 'fdv.ykc@gmail.com';
    formData.contactFormIp = '127.0.0.1';
    formData.contactFormName = 'Yohan-Kévin';
    formData.contactFormLastName = 'COMPANY';
    formData.contactFormSubject = 'Test ' + formData.contactFormSentTimestamp;
    formData.contactFormMessage = this.contactForm.get('message')!.value;

    // this.contactFormService.sendContactForm(formData).subscribe(v => {
    //   console.log(v);
    // }, e => {
    //   console.error(e);
    // });
  }

  ngOnInit(): void {
    // this.albumService.getAlbumList(true).subscribe(res => {
    //   this.jsonHere = JSON.stringify(res,null,2);
    // });

    // this.articleService.getArticleById('65ef6bb87710bc0008e8473c').subscribe(res => {
    //   console.log(res);
    //   this.webinyRichTextObject = res!.articleContent;
    // });

    // this.clientService.getClientById('65edab5a378424000864f0d7', true).subscribe(res => {
    //   this.jsonHere = JSON.stringify(res,null,2);
    // });
    // this.clientService.getClientList().subscribe(res => {
    //   this.jsonHere2 = JSON.stringify(res, null, 2);
    // });

    // this.faqService.getFAQList().subscribe(res => {
    //   this.jsonHere = JSON.stringify(res, null, 2);
    // });

    // this.featureService.getFeatureList().subscribe(res => {
    //   this.jsonHere = JSON.stringify(res, null, 2);
    // });

    // this.menuService.getMenuList(true).subscribe(res => {
    //   this.jsonHere = JSON.stringify(res,null,2);
    // });
    // this.menuService.getMenuById('65fed54ba129520009a2d269', true).subscribe(res => {
    //   this.jsonHere = JSON.stringify(res,null,2);
    // });

    // this.offerService.getOfferList().subscribe(res => {
    //   this.jsonHere = JSON.stringify(res,null,2);
    // });

    this.socialMediaService.getSocialMediaList().subscribe(res => {
      this.jsonHere = JSON.stringify(res,null,2);
    });

    this.teamService.getTeamList(true).subscribe(res => {
      this.jsonHere2 = JSON.stringify(res,null,2);
    });

    // this.teamService.getCollaboratorsByTeamId('65fb0e59bc97170008594a26').subscribe(res => {
    //   this.jsonHere = JSON.stringify(res,null,2);
    // });
  }
}
