export interface IFAQ {
  entryId?: string;
  faqQuestion: string;
  faqAnswer: string;
  modifiedOn?: string;
}

export class FAQ implements IFAQ {
  entryId?: string;
  faqQuestion: string;
  faqAnswer: string;
  modifiedOn?: string;

  constructor(faq: IFAQ) {
    this.entryId = faq.entryId;
    this.faqAnswer = faq.faqAnswer;
    this.faqQuestion = faq.faqQuestion;
    this.modifiedOn = faq.modifiedOn;
  }
}
