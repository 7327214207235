import { gql } from "apollo-angular";

const LIST_FEATURES_QUERY = gql`
  query {
    listFeatures {
      data {
        entryId
        modifiedOn
        featureName
        featureStatus
      }
    }
  }`;

  export {
    LIST_FEATURES_QUERY,
  };
