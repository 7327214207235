import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LIST_MENUS_QUERY, LIST_MENUS_WITH_ITEMS_QUERY, MENU_BY_ID_QUERY, MENU_WITH_ITEMS_BY_ID_QUERY, } from '../graphql/menu';
import { Menu } from '../models/menu.model';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  constructor(private apollo: Apollo) { }

  getMenuList(withItems: boolean = false): Observable<Menu[]> {
    return this.apollo.watchQuery<any>({
      query: withItems ? LIST_MENUS_WITH_ITEMS_QUERY : LIST_MENUS_QUERY
    }).valueChanges.pipe(map(
      (result) => result.data.listMenuEntries.data.map((c: any) => new Menu(c))
      ));
  }

  getMenuById(id: string, withItems: boolean = false): Observable<Menu | null> {
    return this.apollo.watchQuery<any>({
      query: withItems ? MENU_WITH_ITEMS_BY_ID_QUERY : MENU_BY_ID_QUERY,
      variables: {
        id: id
      }
    }).valueChanges.pipe(
      map((v: any) => v.data.getMenuEntry.data),
      map((c: any) => new Menu(c))
    );
  }
}
