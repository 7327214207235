import { Time } from "@angular/common";

export interface IMondayTimeBlocks {
  mondayOpen: Time;
  mondayClose: Time;
}

export interface ITuesdayTimeBlocks {
  tuesdayOpen: Time;
  tuesdayClose: Time;
}

export interface IWednesdayTimeBlocks {
  wednesdayOpen: Time;
  wednesdayClose: Time;
}

export interface IThursdayTimeBlocks {
  thursdayOpen: Time;
  thursdayClose: Time;
}

export interface IFridayTimeBlocks {
  fridayOpen: Time;
  fridayClose: Time;
}

export interface ISaturdayTimeBlocks {
  saturdayOpen: Time;
  saturdayClose: Time;
}

export interface ISundayTimeBlocks {
  sundayOpen: Time;
  sundayClose: Time;
}

export interface IClientOpeningHoursIbisDev {
  mondayHours?: { mondayTimeBlocks: IMondayTimeBlocks[] };
  tuesdayHours?: { tuesdayTimeBlocks: ITuesdayTimeBlocks[] };
  wednesdayHours?: { wednesdayTimeBlocks: IWednesdayTimeBlocks[] };
  thursdayHours?: { thursdayTimeBlocks: IThursdayTimeBlocks[] };
  fridayHours?: { fridayTimeBlocks: IFridayTimeBlocks[] };
  saturdayHours?: { saturdayTimeBlocks: ISaturdayTimeBlocks[] };
  sundayHours?: { sundayTimeBlocks: ISundayTimeBlocks[] };
}

/**
 * @param  ibisDevHours Heures d'ouverture au format IbisDev
 * @returns Un tableau d'index 0 à 6 pour les jours de la semaine, contenant des time blocks [OpenTime,CloseTime]
 */
export class ClientOpeningHours {
  [key: number]: [Time, Time][];
  constructor(ibisDevHours: IClientOpeningHoursIbisDev) {
    if (ibisDevHours.mondayHours) {
      for (const timeBlock of ibisDevHours.mondayHours.mondayTimeBlocks) {
        if (!this[0]) { this[0] = []; }
        this[0].push([timeBlock.mondayOpen, timeBlock.mondayClose]);
      }
    }

    if (ibisDevHours.tuesdayHours) {
      if (!this[1]) { this[1] = []; }
      for (const timeBlock of ibisDevHours.tuesdayHours.tuesdayTimeBlocks) {
        this[1].push([timeBlock.tuesdayOpen, timeBlock.tuesdayClose]);
      }
    }

    if (ibisDevHours.wednesdayHours) {
      if (!this[2]) { this[2] = []; }
      for (const timeBlock of ibisDevHours.wednesdayHours.wednesdayTimeBlocks) {
        this[2].push([timeBlock.wednesdayOpen, timeBlock.wednesdayClose]);
      }
    }

    if (ibisDevHours.thursdayHours) {
      if (!this[3]) { this[3] = []; }
      for (const timeBlock of ibisDevHours.thursdayHours.thursdayTimeBlocks) {
        this[3].push([timeBlock.thursdayOpen, timeBlock.thursdayClose]);
      }
    }

    if (ibisDevHours.fridayHours) {
      if (!this[4]) { this[4] = []; }
      for (const timeBlock of ibisDevHours.fridayHours.fridayTimeBlocks) {
        this[4].push([timeBlock.fridayOpen, timeBlock.fridayClose]);
      }
    }

    if (ibisDevHours.saturdayHours) {
      if (!this[5]) { this[5] = []; }
      for (const timeBlock of ibisDevHours.saturdayHours.saturdayTimeBlocks) {
        this[5].push([timeBlock.saturdayOpen, timeBlock.saturdayClose]);
      }
    }

    if (ibisDevHours.sundayHours) {
      if (!this[6]) { this[6] = []; }
      for (const timeBlock of ibisDevHours.sundayHours.sundayTimeBlocks) {
        this[6].push([timeBlock.sundayOpen, timeBlock.sundayClose]);
      }
    }
  }
}
