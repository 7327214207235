import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { COLLABORATORS_BY_TEAM_ID_QUERY, LIST_TEAMS_QUERY, LIST_TEAMS_WITH_COLLABORATORS_QUERY, TEAM_BY_ID_QUERY, TEAM_WITH_COLLABORATORS_BY_ID_QUERY } from '../graphql/team';
import { Collaborator, Team } from '../models/team.model';

@Injectable({
  providedIn: 'root'
})
export class TeamService {
  constructor(private apollo: Apollo) { }

  getTeamList(withCollaborators: boolean = false): Observable<Team[]> {
    return this.apollo.watchQuery<any>({
      query: withCollaborators ? LIST_TEAMS_WITH_COLLABORATORS_QUERY : LIST_TEAMS_QUERY
    }).valueChanges.pipe(map(
      (result) => result.data.listTeams.data?.map((c: any) => new Team(c))
      ));
  }

  getTeamById(id: string, withCollaborators: boolean = false): Observable<Team | null> {
    return this.apollo.watchQuery<any>({
      query: withCollaborators ? TEAM_WITH_COLLABORATORS_BY_ID_QUERY : TEAM_BY_ID_QUERY,
      variables: {
        id: id
      }
    }).valueChanges.pipe(
      map((v: any) => v.data.getTeam.data),
      map((c: any) => new Team(c))
    );
  }

  getCollaboratorsByTeamId(teamId: string): Observable<Collaborator[] | null> {
    return this.apollo.watchQuery<any>({
      query: COLLABORATORS_BY_TEAM_ID_QUERY,
      variables: {
        id: teamId
      }
    }).valueChanges.pipe(
      map((v: any) => v.data.getTeam.data?.teamCollaborators?.map((c: any) => c ? new Collaborator(c) : null))
    );
  }
}


