import { gql } from "apollo-angular";

const LIST_TEAMS_WITH_COLLABORATORS_QUERY = gql`{
  listTeams {
    data {
      entryId
      teamTitle
      teamHistory
      teamCollaborators {
        entryId
        collaboratorName
        collaboratorPhoto
        collaboratorPosition
        collaboratorShortDescription
        collaboratorLongDescription
        collaboratorEmail
        collaboratorPhoneNumber
      }
    }
  }
}`;

const LIST_TEAMS_QUERY = gql`{
  listTeams {
    data {
      entryId
      teamTitle
      teamHistory
    }
  }
}`;

const TEAM_BY_ID_QUERY = gql`
  query getTeam($id: String!) {
    getTeam(where: { entryId: $id }) {
      data {
        entryId
        teamTitle
        teamHistory
      }
    }
  }`;

const TEAM_WITH_COLLABORATORS_BY_ID_QUERY = gql`
  query getTeam($id: String!) {
    getTeam(where: { entryId: $id }) {
      data {
        entryId
        teamTitle
        teamHistory
        teamCollaborators {
          entryId
          collaboratorName
          collaboratorPhoto
          collaboratorPosition
          collaboratorShortDescription
          collaboratorLongDescription
          collaboratorEmail
          collaboratorPhoneNumber
        }
      }
    }
  }`;

const COLLABORATORS_BY_TEAM_ID_QUERY = gql`
  query getTeam($id: String!) {
    getTeam(where: { entryId: $id }) {
      data {
        entryId
      teamCollaborators {
        entryId
        collaboratorName
        collaboratorPhoto
        collaboratorPosition
        collaboratorShortDescription
        collaboratorLongDescription
        collaboratorEmail
        collaboratorPhoneNumber
      }
      }
    }
  }`;

export {
  LIST_TEAMS_QUERY,
  LIST_TEAMS_WITH_COLLABORATORS_QUERY,
  TEAM_BY_ID_QUERY,
  TEAM_WITH_COLLABORATORS_BY_ID_QUERY,
  COLLABORATORS_BY_TEAM_ID_QUERY,
};
