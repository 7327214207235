
export interface IFeature {
  entryId?: string;
  featureName: string;
  featureStatus: boolean;
  modifiedOn?: string;
}

export class Feature implements IFeature {
  entryId?: string;
  featureName: string;
  featureStatus: boolean;
  modifiedOn?: string;

  constructor(Feature: IFeature) {
    this.entryId = Feature.entryId;
    this.featureName = Feature.featureName;
    this.featureStatus = Feature.featureStatus;
    this.modifiedOn = Feature.modifiedOn;
  }
}
