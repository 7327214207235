import { gql } from "apollo-angular";

const LIST_MENUS_QUERY = gql`
  query {
    listMenuEntries {
      data {
        entryId
        modifiedOn
        menuTitle
      }
    }
  }`;

const LIST_MENUS_WITH_ITEMS_QUERY = gql`
  query {
    listMenuEntries {
      data {
        entryId
        modifiedOn
        menuTitle
        menuItem {
          menuItemTitle
          menuItemSlug
          subMenuItem {
            subMenuItemTitle
            subMenuItemSlug
            subSubMenuItem {
              subSubMenuTitle
              subSubMenuItemSlug
            }
          }
        }
      }
    }
  }`;

const MENU_WITH_ITEMS_BY_ID_QUERY = gql`
  query getMenuEntry($id: String!) {
    getMenuEntry(where: { entryId: $id }) {
      data {
        entryId
        modifiedOn
        menuTitle
        menuItem {
          menuItemTitle
          menuItemSlug
          subMenuItem {
            subMenuItemTitle
            subMenuItemSlug
            subSubMenuItem {
              subSubMenuTitle
              subSubMenuItemSlug
            }
          }
        }
      }
    }
  }`;

const MENU_BY_ID_QUERY = gql`
  query getMenuEntry($id: String!) {
    getMenuEntry(where: { entryId: $id }) {
      data {
        entryId
        modifiedOn
        menuTitle
      }
    }
  }`;

export {
  LIST_MENUS_QUERY,
  LIST_MENUS_WITH_ITEMS_QUERY,
  MENU_WITH_ITEMS_BY_ID_QUERY,
  MENU_BY_ID_QUERY,
};
