export interface IContactForm {
  entryId?: string;
  contactFormName: string;
  contactFormLastName: string;
  contactFormEmail: string;
  contactFormSubject: string;
  contactFormMessage: string;
  contactFormIp: string;
  contactFormSentTimestamp: string;
  modifiedOn?: string;
}

export class ContactForm implements IContactForm {
  entryId?: string;
  contactFormName: string = '';
  contactFormLastName: string = '';
  contactFormEmail: string = '';
  contactFormSubject: string = '';
  contactFormMessage: string = '';
  contactFormIp: string = '';
  contactFormSentTimestamp: string = '';
  modifiedOn?: string;

  constructor() {
    this.contactFormSentTimestamp = ((new Date()).getTime() / 1000).toString();
  }
}
