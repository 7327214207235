export const environment = {
  API_BASE_URL: 'https://api.yco-consulting.com/cms',
  API_GRAPHQL_URL: 'https://api.yco-consulting.com/graphql',
  API_READ_PATH: '/read',
  API_PREVIEW_PATH: '/preview',
  API_MANAGE_PATH: '/manage',
  API_DEFAULT_LOCALE: 'fr-FR',
  API_KEY: 'a564e7b1b6e14218fa42fe87482bf0479d92305b26938455',
  API_TENANT_ID: '6602e2fce5ab5600089a18a7'
};
