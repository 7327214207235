import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PHOTOS_BY_ALBUM_ID_QUERY, LIST_ALBUMS_QUERY, LIST_ALBUMS_WITH_PHOTOS_QUERY, ALBUM_BY_ID_QUERY, ALBUM_WITH_PHOTOS_BY_ID_QUERY } from '../graphql/album';
import { Photo, Album } from '../models/album.model';

@Injectable({
  providedIn: 'root'
})
export class AlbumService {
  constructor(private apollo: Apollo) { }

  getAlbumList(withPhotos: boolean = false): Observable<Album[]> {
    return this.apollo.watchQuery<any>({
      query: withPhotos ? LIST_ALBUMS_WITH_PHOTOS_QUERY : LIST_ALBUMS_QUERY
    }).valueChanges.pipe(map(
      (result) => result.data.listAlbums.data?.map((c: any) => new Album(c))
      ));
  }

  getAlbumById(id: string, withPhotos: boolean = false): Observable<Album | null> {
    return this.apollo.watchQuery<any>({
      query: withPhotos ? ALBUM_WITH_PHOTOS_BY_ID_QUERY : ALBUM_BY_ID_QUERY,
      variables: {
        id: id
      }
    }).valueChanges.pipe(
      map((v: any) => v.data.getAlbum.data),
      map((c: any) => new Album(c))
    );
  }

  getPhotosByAlbumId(albumId: string): Observable<Photo[] | null> {
    return this.apollo.watchQuery<any>({
      query: PHOTOS_BY_ALBUM_ID_QUERY,
      variables: {
        id: albumId
      }
    }).valueChanges.pipe(
      map((v: any) => v.data.getAlbum.data?.albumPhotos?.map((c: any) => c ? new Photo(c) : null))
    );
  }
}


