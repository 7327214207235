import { ClientOpeningHours, IClientOpeningHoursIbisDev } from "./client.opening-hours.interface";

export interface IClient {
  entryId?: string;
  clientCompanyName: string;
  clientCompanyType: string;
  clientSocialCapital: number;
  clientLegalRepresentative: string;
  clientRcs: string;
  clientEmail: string;
  clientOpeningHours?: IClientOpeningHoursIbisDev;
  clientOpeningHoursArray?: ClientOpeningHours;
  clientLogo: string;
  clientHeadOfficeAddress: string;
  clientCnil: string,
  clientPhoneNumber: string;
}

export class Client implements IClient {
  entryId?: string;
  clientCompanyName: string;
  clientCompanyType: string;
  clientSocialCapital: number;
  clientLegalRepresentative: string;
  clientRcs: string;
  clientEmail: string;
  clientOpeningHours?: IClientOpeningHoursIbisDev;
  clientOpeningHoursArray?: ClientOpeningHours | undefined;
  clientLogo: string;
  clientHeadOfficeAddress: string;
  clientCnil: string;
  clientPhoneNumber: string;

  constructor(client: IClient) {
    this.entryId = client.entryId;
    this.clientCompanyName = client.clientCompanyName;
    this.clientCompanyType = client.clientCompanyType;
    this.clientSocialCapital = client.clientSocialCapital;
    this.clientLegalRepresentative = client.clientLegalRepresentative;
    this.clientRcs = client.clientRcs;
    this.clientEmail = client.clientEmail;
    this.clientOpeningHours = client.clientOpeningHours;

    if (client.clientOpeningHours) {
      this.clientOpeningHoursArray = new ClientOpeningHours(client.clientOpeningHours);
      delete this.clientOpeningHours;
    }

    this.clientLogo = client.clientLogo;
    this.clientHeadOfficeAddress = client.clientHeadOfficeAddress;
    this.clientCnil = client.clientCnil;
    this.clientPhoneNumber = client.clientPhoneNumber;
  }
}
