import { SerializedLexicalEditorState } from "@yco-consulting/ngx-webiny-richtext-parser";

export interface ISocialMediaOtherSelection {
  socialMediaOtherSelectionName: string;
  socialMediaOtherSelectionLogo: string;
}

export interface ISocialMedia {
  entryId?: string;
  socialMediaTitle: string;
  socialMediaUrl: string;
  socialMediaAltText: string;
  socialMediaSelection: string;
  socialMediaOtherSelection?: ISocialMediaOtherSelection;
  modifiedOn?: string;
}

export class SocialMedia implements ISocialMedia {
  entryId?: string;
  socialMediaTitle: string;
  socialMediaUrl: string;
  socialMediaAltText: string;
  socialMediaSelection: string;
  socialMediaOtherSelection?: ISocialMediaOtherSelection;
  modifiedOn?: string;

  constructor(SocialMedia: ISocialMedia) {
    this.entryId = SocialMedia.entryId;
    this.socialMediaTitle = SocialMedia.socialMediaTitle;
    this.socialMediaUrl = SocialMedia.socialMediaUrl;
    this.socialMediaAltText = SocialMedia.socialMediaAltText;
    this.socialMediaSelection = SocialMedia.socialMediaSelection;
    this.socialMediaOtherSelection = SocialMedia.socialMediaOtherSelection;
    this.modifiedOn = SocialMedia.modifiedOn;
  }
}
