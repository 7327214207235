import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LIST_FAQS_QUERY } from '../graphql/faq';
import { FAQ } from '../models/faq.model';

@Injectable({
  providedIn: 'root'
})
export class FAQService {
  constructor(private apollo: Apollo) { }

  getFAQList(): Observable<FAQ[]> {
    return this.apollo.watchQuery<any>({
      query: LIST_FAQS_QUERY
    }).valueChanges.pipe(map(
      (result) => result.data.listFaqs.data.map((c: any) => new FAQ(c))
      ));
  }
}


