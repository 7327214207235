import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LIST_SOCIAL_MEDIAS_QUERY } from '../graphql/social-media';
import { SocialMedia } from '../models/social-media.model';

@Injectable({
  providedIn: 'root'
})
export class SocialMediaService {
  constructor(private apollo: Apollo) { }

  getSocialMediaList(): Observable<SocialMedia[]> {
    return this.apollo.watchQuery<any>({
      query: LIST_SOCIAL_MEDIAS_QUERY
    }).valueChanges.pipe(map(
      (result) =>  result.data.listSocialMediaEntries.data ? result.data.listSocialMediaEntries.data.map((c: any) => new SocialMedia(c)) : []
      ));
  }
}

