import { Apollo, APOLLO_NAMED_OPTIONS, NamedOptions } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ApplicationConfig, inject } from '@angular/core';
import { ApolloClientOptions, ApolloLink, InMemoryCache } from '@apollo/client/core';
import { environment } from '../environments/environment';
import { HttpHeaders } from '@angular/common/http';

// const uri = 'https://d1defe6z4awtzz.cloudfront.net/graphql'; // <-- add the URL of the GraphQL server here
const uri = environment.API_GRAPHQL_URL; // <-- add the URL of the GraphQL server here
export function apolloOptionsFactory(): ApolloClientOptions<any> {
  const httpLink = inject(HttpLink);
  return {
    link: httpLink.create({ uri }),
    cache: new InMemoryCache(),
  };
}

export const graphqlProvider: ApplicationConfig['providers'] = [
  Apollo,
  {
    provide: APOLLO_NAMED_OPTIONS,
    useFactory(httpLink: HttpLink): NamedOptions {
      const http = httpLink.create({ uri: environment.API_BASE_URL + environment.API_READ_PATH + '/' + environment.API_DEFAULT_LOCALE });
      const httpManage = httpLink.create({ uri: environment.API_BASE_URL + environment.API_MANAGE_PATH + '/' + environment.API_DEFAULT_LOCALE });
      const middleware = new ApolloLink((operation, forward) => {
        operation.setContext({
          headers: new HttpHeaders().set(
            'Authorization',
            `Bearer ${environment.API_KEY || null}`,
          ).set('x-tenant', environment.API_TENANT_ID),
        });
        return forward(operation);
      });

      const link = middleware.concat(http);
      const linkManage = middleware.concat(httpManage);

      return {
        default: {
          link,
          cache: new InMemoryCache(),
        },
        manage: {
          link: linkManage,
          cache: new InMemoryCache()
        }
      };
    },
    deps: [HttpLink],
  },
];
