import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CLIENT_BY_ID_QUERY, CLIENT_BY_ID_WITH_OPENING_HOURS_QUERY, LIST_CLIENTS_QUERY, LIST_CLIENTS_WITH_OPENIG_HOURS_QUERY, OPENING_HOURS_BY_CLIENT_ID_QUERY } from '../graphql/client';
import { Client } from '../models/client.interface';
import { ClientOpeningHours } from "../models/client.opening-hours.interface";

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  constructor(private apollo: Apollo) { }

  getClientList(withOpeningHours: boolean = false): Observable<Client[]> {
    return this.apollo.watchQuery<any>({
      query: withOpeningHours ? LIST_CLIENTS_WITH_OPENIG_HOURS_QUERY : LIST_CLIENTS_QUERY
    }).valueChanges.pipe(map(
      (result) => result.data.listClients.data.map((c: any) => new Client(c))
      ));
  }

  getClientById(id: string, withOpeningHours: boolean = false): Observable<Client | null> {
    return this.apollo.watchQuery<any>({
      query: withOpeningHours ? CLIENT_BY_ID_WITH_OPENING_HOURS_QUERY : CLIENT_BY_ID_QUERY,
      variables: {
        id: id
      }
    }).valueChanges.pipe(
      map((v: any) => v.data.getClient.data),
      map((c: any) => new Client(c))
    );
  }

  getOpeningHoursByClientId(clientId: string): Observable<ClientOpeningHours | null> {
    return this.apollo.watchQuery<any>({
      query: OPENING_HOURS_BY_CLIENT_ID_QUERY,
      variables: {
        id: clientId
      }
    }).valueChanges.pipe(
      map((v: any) => v.data.getClient.data?.clientOpeningHours),
      map((c: any) => c ? new ClientOpeningHours(c) : null)
    );
  }
}


