import { gql } from "apollo-angular";

const LIST_ARTICLES_WITH_CONTENT_QUERY = gql`{
  listArticles {
    data {
      entryId
      articleTitle
      articleImage
      articleAuthor
      articleContent
      modifiedOn
    }
  }
}`;

const LIST_ARTICLES_QUERY = gql`{
  listArticles {
    data {
      entryId
      articleTitle
      articleImage
      articleAuthor
      modifiedOn
    }
  }
}`;

const ARTICLE_BY_ID_QUERY = gql`
  query getArticle($id: String!) {
    getArticle(where: { entryId: $id }) {
      data {
        entryId
        articleTitle
        articleImage
        articleAuthor
        articleContent
        modifiedOn
      }
    }
  }`;

export {
  LIST_ARTICLES_QUERY,
  LIST_ARTICLES_WITH_CONTENT_QUERY,
  ARTICLE_BY_ID_QUERY,
};
