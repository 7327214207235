export interface IAlbum {
  entryId: string;
  albumTitle: string;
  albumPhotos: IPhoto[];
}
export interface IPhoto {
  entryId: string;
  photoTitle: string;
  photoImage?: string;
  photoDescription: string;
  photoAltText: string;
  photoTags?: string[];
  photoHypertextLink?: string;
}

export class Album implements IAlbum {
  entryId: string;
  albumTitle: string;
  albumHistory?: string;
  albumPhotos: IPhoto[];

  constructor(album: IAlbum) {
    this.entryId = album.entryId;
    this.albumTitle = album.albumTitle;
    this.albumPhotos = album.albumPhotos;
  }
}

export class Photo implements IPhoto {
  entryId: string;
  photoTitle: string;
  photoImage?: string | undefined;
  photoDescription: string;
  photoTags?: string[];
  photoHypertextLink?: string;
  photoAltText: string;

  constructor(collab: IPhoto) {
    this.entryId = collab.entryId;
    this.photoTitle = collab.photoTitle;
    this.photoImage = collab.photoImage;
    this.photoDescription = collab.photoDescription;
    this.photoTags = collab.photoTags;
    this.photoAltText = collab.photoAltText;
    this.photoHypertextLink = collab.photoHypertextLink;
  }
}
