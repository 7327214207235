import { gql } from "apollo-angular";

const LIST_FAQS_QUERY = gql`
  query {
    listFaqs {
      data {
        entryId
        modifiedOn
        faqQuestion
        faqAnswer
      }
    }
  }`;

  export {
    LIST_FAQS_QUERY,
  };
