import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LIST_OFFERS_QUERY } from '../graphql/offer';
import { Offer } from '../models/offer.model';

@Injectable({
  providedIn: 'root'
})
export class OfferService {
  constructor(private apollo: Apollo) { }

  getOfferList(): Observable<Offer[]> {
    return this.apollo.watchQuery<any>({
      query: LIST_OFFERS_QUERY
    }).valueChanges.pipe(map(
      (result) => result.data.listOffers.data.map((c: any) => new Offer(c))
      ));
  }
}
