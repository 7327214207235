import { SerializedLexicalEditorState } from "@yco-consulting/ngx-webiny-richtext-parser";

export interface IOffer {
  entryId?: string;
  offerTitle: string;
  offerPrice: number;
  offerPeridiocity: string;
  offerCtaText: string;
  offerCtaUrl: string;
  offerContent: SerializedLexicalEditorState;
  modifiedOn?: string;
}

export class Offer implements IOffer {
  entryId?: string;
  offerTitle: string;
  offerPrice: number;
  offerPeridiocity: string;
  offerCtaText: string;
  offerCtaUrl: string;
  offerContent: SerializedLexicalEditorState;
  modifiedOn?: string;

  constructor(Offer: IOffer) {
    this.entryId = Offer.entryId;
    this.offerTitle = Offer.offerTitle;
    this.offerPrice = Offer.offerPrice;
    this.offerPeridiocity = Offer.offerPeridiocity;
    this.offerCtaText = Offer.offerCtaText;
    this.offerCtaUrl = Offer.offerCtaUrl;
    this.offerContent = Offer.offerContent;
    this.modifiedOn = Offer.modifiedOn;
  }
}
