import { gql } from "apollo-angular";

const LIST_OFFERS_QUERY = gql`
  query {
    listOffers {
      data {
        entryId
        modifiedOn
        offerTitle
        offerPrice
        offerPeridiocity
        offerCtaText
        offerCtaUrl
        offerContent
      }
    }
  }`;

  export {
    LIST_OFFERS_QUERY,
  };
