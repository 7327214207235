import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LIST_ARTICLES_QUERY, ARTICLE_BY_ID_QUERY, LIST_ARTICLES_WITH_CONTENT_QUERY } from '../graphql/article';
import { Article } from '../models/article.model';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {
  constructor(private apollo: Apollo) { }

  getArticleList(withContent: boolean = false): Observable<Article[]> {
    return this.apollo.watchQuery<any>({
      query: withContent ? LIST_ARTICLES_WITH_CONTENT_QUERY : LIST_ARTICLES_QUERY
    }).valueChanges.pipe(map(
      (result) => result.data.listArticles.data.map((c: any) => new Article(c))
      ));
  }

  getArticleById(id: string): Observable<Article | null> {
    return this.apollo.watchQuery<any>({
      query: ARTICLE_BY_ID_QUERY,
      variables: {
        id: id
      }
    }).valueChanges.pipe(
      map((v: any) => v.data.getArticle.data),
      map((c: any) => new Article(c))
    );
  }
}


